import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dependentField" ]
  
  updateDependentFields(event) {
    const $originalSelect2Event = event.detail.event;

    this.dependentFieldTargets.forEach((dependentField) => {
      let team_id = dependentField.getAttribute('data-team-id');
      let $table_name = $originalSelect2Event.target.value;

      $.get( "/account/teams/"+team_id+"/templates/table_fields?table_name="+$table_name+"", function(data) {
        $(dependentField).select2({
          data: data.fields
        }).on('change', function (e) {
          $('#template_merge_field_value').val(`#{params.dig('${$table_name}', '${this.value}')}`);
          
          // Table field's JSON data preview
          if ( $('#template_json_field_data_preview').val() === 'true' ){
            updateFieldJson(team_id, $table_name, this.value);
          }
        });

        // Full width issue
        $(dependentField).next().css("width","100%");
      });
    })
  }
}
